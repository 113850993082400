import React from "react"
import PropTypes from "prop-types";
import { Link } from 'gatsby'

const Footer = ({ legalInfo, copyright, cookiePolicyText, privacyPolicyText, pageNotFound }) => (
  <footer
    style={{backgroundColor: '#F8F9FA'}}
    className="footer mt-auto py-3">
    <div className="container-fluid d-flex justify-content-between">
      <span className="text-muted">{copyright}</span>
      <div className="d-flex">
        <Link
          className="mr-4"
          to={legalInfo ? "../cookie-policy" : pageNotFound ? "/cookie-policy" : "cookie-policy"}>
            {cookiePolicyText}
        </Link>
        <Link
          to={legalInfo ? "../privacy-policy" : pageNotFound ? "/privacy-policy" : "privacy-policy"}>
            {privacyPolicyText}
        </Link>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  legalInfo: PropTypes.string,
  copyright: PropTypes.string,
  cookiePolicyText: PropTypes.string,
  privacyPolicyText: PropTypes.string,
};

export default Footer
